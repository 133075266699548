const modal = document.querySelector(".popup");

if (modal) {
    const popup = () =>{
        modal.style.display = "flex";
        const span = document.getElementsByClassName("close")[0];
        span.onclick = function() {
            modal.style.display = "none";
            Cookies.set('hide-news', 1, { expires: 7 });
        }

        const button = modal.getElementsByClassName('button')[0];
        button.onclick = function () {
            Cookies.set('hide-news', 1, { expires: 7 });
        }

        window.onclick = function(event) {
            if (event.target == modal) {
                modal.style.display = "none";
                Cookies.set('hide-news', 1);
            }
        }
    }

    if (Cookies.get('hide-news')) {
        modal.style.display = "none";
    }
    else {
        document.addEventListener('DOMContentLoaded', () => {
        popup();
    })
    }
}
